import { Controller } from "@hotwired/stimulus"
import 'chartjs-adapter-moment';
import zoomPlugin from 'chartjs-plugin-zoom';


import {
    Chart,
} from 'chart.js';


Chart.register(zoomPlugin);

export default class extends Controller {
    static values = {
        metrics: Object, title: String, color: String,
        metrics2: Array, title2: String, color2: String,
        label: String,
    }
    connect() {
        const ctx = this.element.getContext('2d');
        const chart = new Chart(ctx, this.metricsValue)
    }
}
