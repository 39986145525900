import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["button"]

  static values = {
    frame: String,
    class: String,
    inactiveClass: String,
    activeClass: String
  }

  connect() {
    this.buttonTargets.forEach((button) => {
      button.classList.add(...this.classArray())
    })

    this.setActiveFromFrame()
  }

  setActiveFromFrame() {
    this.buttonTargets.forEach((button) => {
      this.clearButton(button)
    })

    const frame = document.getElementById(this.frameValue)
    const frameSrc = frame.src

    this.buttonTargets.forEach((button) => {
      // If button href contains frameSrc, set button to active
      if (button.href.includes(frameSrc)) {
        this.setActiveButton(button)
      }
    })

    setTimeout(() => {
      this.setActiveFromFrame()
    }, 1000)
  }

  toggle(e) {
    this.buttonTargets.forEach((button) => {
      this.clearButton(button)
    })

    this.setActiveButton(e.target)
  }

  clearButton(button) {
    button.classList.remove(...this.activeClassArray())
    button.classList.add(...this.inactiveClassArray())
  }

  setActiveButton(target) {
    target.classList.remove(...this.inactiveClassArray())
    target.classList.add(...this.activeClassArray())
  }

  activeClassArray() {
    return this.activeClassValue.split(" ")
  }

  classArray() {
    return this.classValue.split(" ")
  }

  inactiveClassArray() {
    return this.inactiveClassValue.split(" ")
  }
}
